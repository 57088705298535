/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://s3zw2cdngjdmnfkphgrugk3wea.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-idcb3ezrjbarpfq6khx3obhafi",
    "aws_cloud_logic_custom": [
        {
            "name": "restApi",
            "endpoint": "https://iw3t9ja482.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:3aa75a9e-a2ee-4205-811b-5d6e1c82bf95",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_SNK32xI4D",
    "aws_user_pools_web_client_id": "3052i6d95ber4f0hmu1nlopk5p",
    "oauth": {
        "domain": "clarityapp.auth.us-east-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "clarityapp://,expertapp://,http://localhost:3000,https://admin.dev.clarityapp.io",
        "redirectSignOut": "clarityapp://,expertapp://,http://localhost:3000,https://admin.dev.clarityapp.io",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "clarity-develop",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
